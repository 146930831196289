.feed-content-layer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  $adfit-height: 90px;
  &.mobile {
    bottom: calc(60px + #{$adfit-height});
  }
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;

  -webkit-animation: feedContentLayerBackgroundOpen 0.3s;
  -o-animation: feedContentLayerBackgroundOpen 0.3s;
  animation: feedContentLayerBackgroundOpen 0.3s;

  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -ms-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;

  &.closing {
    opacity: 0;
    > .scrollable {
      right: -80%;
    }
  }

  > .scrollable {
    height: 100%;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    min-width: 80%;
    width: 750px;
    padding: 0;

    -webkit-animation: scrollableOpen 300ms;
    -o-animation: scrollableOpen 300ms;
    animation: scrollableOpen 300ms;

    -webkit-transition: right 300ms;
    -moz-transition: right 300ms;
    -ms-transition: right 300ms;
    -o-transition: right 300ms;
    transition: right 300ms;

    > div {
      height: 100%;
      overflow-y: auto;
    }

    > div > div {
      padding: 55px 2.4rem 10px;
      position: relative;

      > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      > .share-btn {
        position: absolute;
        right: 50px;
        top: 14px;
        cursor: pointer;
        width: 30px !important;
        height: 30px !important;
      }

      div {
        max-width: 630px;
        margin: auto;

        img {
          max-width: 100%;
        }

        > .title {
          font-size: 2rem;
          font-weight: 900;
          display: block;
        }
        > .by {
          margin: 10px 0 20px;
          color: rgb(158, 158, 158);
          font-size: 13px;
          .project-name {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        > .feed-img {
          display: block;
          margin: auto auto 20px;
        }

        > .content {
          line-height: 1.4em;
          > * {
            max-width: 100%;
          }
        }

        .visit-btn {
          display: block;
          margin: 30px 0;
          width: 100%;
        }
      }
    }
  }

  .left-button,
  .right-button {
    position: absolute;
    top: 50%;
  }
  .left-button {
    left: 5px;
  }
  .right-button {
    right: 5px;
  }
}

@keyframes feedContentLayerBackgroundOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scrollableOpen {
  from {
    right: -80%;
  }
  to {
    right: 0;
  }
}
