.search-input-container {
  position: relative;
  text-align: left;
  display: inline-block;
  line-height: 1em;

  .search-input {
    border: solid 1px #666;

    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    outline: none;

    width: 100%;
    max-width: 400px;
    font-size: 14px;

    padding: 3px 10px 3px 10px;
    position: relative;

    &::placeholder {
      color: #cccccc;
    }

    &.error{
      border-color: #be4b49;
    }
  }

  .instant-search-result {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;

    text-align: left;
    top: 100%;
    background-color: #ffffff;
    max-width: 300px;
    border: 1px solid #c8c8c8;
    color: #000;
    font-size: 13px;

    @media (max-width: 320px) {
      width: 230px;
    }
    @media (min-width: 320px) {
      width: 280px;
    }

    .instant-search-result-item {
      overflow: hidden;
      padding: 5px;
      font-size: 14px;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        background-color: rgb(238, 240, 247);
      }

      div.thumbnail {
        height: 30px;
        width: 30px;
        background: no-repeat center center;
        background-size: cover;
        vertical-align: middle;
        border: 1px solid #c8c8c8;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        display: inline-block;
        margin-right: 5px;
      }

      span.name {
        vertical-align: middle;
      }
    }
  }

}