.subscribe-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.76);
  color: #FFFFFF;
  text-align: center;

  .content {
    position: absolute;
    top: 50%;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    width: 100%;
    padding: 20px;
    font-weight: bold;

    font-size: 40px;
    @media (max-width: 340px) {
      font-size: 20px;
    }
    @media (min-width: 340px) and (max-width: 420px) {
      font-size: 30px;
    }

    p {
      padding: 30px;
    }

    button {
      border: 2px solid #FFFFFF;
      background-color: transparent;
      color: #FFFFFF;
      font-size: 30px;
      font-weight: bold;
      padding: 8px 40px;
      cursor: pointer;
      outline: none;
      margin-bottom: 20px;

      @media (max-width: 340px) {
        font-size: 20px;
        padding: 4px 20px;
      }
      @media (min-width: 340px) and (max-width: 420px) {
        font-size: 25px;
        padding: 6px 25px;
      }
    }
  }

  .link {
    margin-top: 20px;
    a {
      word-break: break-all;
      text-decoration: underline;
      color: #FFFFFF;
    }
  }

  .close-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}