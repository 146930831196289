.tooltip-input-wrapper {
  .tooltip {
    -webkit-animation: tooltip_animation 0.15s;
    -o-animation: tooltip_animation 0.15s;
    animation: tooltip_animation 0.15s;

    position: absolute;
    padding: 4px 7px;
    font-size: 12px;

    background-color: #be4b49;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(139, 3, 0, .75), 0 1px 10px rgba(0, 0, 0, .35);
    color: #fff;

    text-align: left;
    line-height: 1.5em;

    white-space: normal;
    width: 250px;
  }

  .error-icon {
    position: absolute;
    right: 2px;
    top: 1px;

    .background {
      background-color: #ffffff;
      position: absolute;
      left: 20%;
      top: 20%;
      bottom: 20%;
      right: 20%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }
  @keyframes tooltip_animation {
    0% {
      opacity: 0.3;
      transform: translateY(2px);
    }
    100% {
      opacity: 1;
    }
  }
}