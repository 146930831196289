.feed-item {
  &.thumbnail {
    position: relative;
    margin-bottom: 20px;
    color: #000000;
    display: flex;
    .thumbnail {
      cursor: pointer;
      float: left;
      margin-right: 10px;
      flex: 0 0 auto;
    }
    p {
      margin: 0;
    }
    .title {
      font-weight: bold;
      margin-top: -4px;
      cursor: pointer;
      font-size: 16px;
    }
    .by {
      margin: 0.5rem 0;
      color: rgb(158, 158, 158);
      font-size: 12px;
      a:hover {
        text-decoration: underline;
      }
    }
    .summary {
      overflow: hidden;
      margin: 3px 2px 10px;
      font-size: 0.8rem;
      height: 5.5rem;
      line-height: 1.1rem;
      word-break: break-word;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      color: rgb(158, 158, 158);
      cursor: pointer;
    }
  }

  &.title-only {
    border-top: 1px solid #efefef;
    font-size: 13px;
    padding: 10px;
    .project {
      width: 100px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #9e9e9e;
      &:hover {
        text-decoration: underline;
      }
    }
    .title {
      cursor: pointer;
      display: inline-block;
      width: calc(100% - 170px);
      font-weight: bold;
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      .description {
        font-weight: normal;
        color: #999999;
        margin-left: 5px;
      }
    }
    .date {
      display: inline-block;
      width: 70px;
      text-align: right;
    }

    &.mobile {
      .date {
        vertical-align: middle;
      }
      .project {
        vertical-align: middle;
        width: calc(100% - 70px);
      }
      .title {
        display: block;
        padding-left: 0;
        width: auto;
        margin-top: 5px;
        text-overflow: ellipsis;
      }
    }
  }
}