.subscribe-option-menu {
  position: absolute;
  top: 45px;
  right: 0;
  width: 280px;
  background-color: #FFFFFF;
  z-index: 1;
  outline: none;

  padding: 10px;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .28);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .28);
  box-shadow: 0 1px 8px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .28);
  -webkit-animation: fadein 300ms;
  -o-animation: fadein 300ms;
  animation: fadein 300ms;

  > .arrow {
    right: 25px;
    position: absolute;
    overflow: hidden;
    width: 26px;
    height: 13px;
    top: -13px;
    &:after {
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: #fff;
      transform: rotate(45deg);
      top: 6px;
      left: 6px;

      box-shadow: 1px 1px 8px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .28);
    }
  }

  &.left {
    right: auto;
    top: 30px;
    left: -90px;
    > .arrow {
      right: 160px;
    }
  }

  .filter-list {
    margin: 10px 0;
    padding: 0 3px;
    font-size: 13px;
    .label {
      margin-bottom: 3px;
    }
    .filter-item {
      position: relative;
      padding: 5px 10px;
      &:before {
        content: '-';
        position: relative;
        left: -4px;
      }

      .delete-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
        color: rgb(239, 83, 80);
        font-size: 15px;
      }
    }
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #c8c8c8;
      font-size: 14px;
      line-height: 1.3em;
      display: block;
      margin: 5px 0;
      &:focus {
        outline: none;
      }
    }
  }

  button {
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
    color: #333333;

    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
    }
  }
}