ul.subscription-sortable-list {
  li.subscription-sortable {
    max-height: 62px;
    transition: max-height 0.1s ease-out;
    overflow: hidden;
  }
  &.folder-moving {
    li.subscription-sortable {
      height: 0;
      max-height: 0;
    }
  }
}