.project-description {
  position: relative;
  margin-bottom: 48px;
  @media(max-width: 420px) {
    margin-bottom: 18px;
  }
  >.left {
    width: 80px;
    display: inline-block;
    vertical-align: top;
  }
  >.left + .right {
    width: calc(100% - 80px);
  }
  >.right {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: #757575;
    font-size: 12px;
  }

  .title {
    color: #000;
    font-size: 2rem;
    font-weight: bold;
    padding-right: 5em;
    @media(max-width: 420px) {
      font-size: 1.25rem;
    }
  }

  .description {
    font-size: 14px;
    line-height: 1.25rem;
  }
}