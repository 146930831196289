html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

button {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 10px;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  box-shadow: 0 0 5px #efefef;
  font-weight: bold;
  color: #333;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }

  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;

  &.primary {
    background-color: #2196f3;
    font-weight: bold;
    color: #ffffff;
    border: none;
  }
  &.white-color {
    color: #ffffff;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.app {
  $drawer-open-width: 240px;
  $drawer-width: 50px;
  $appbar-height: 57px;
  $tab-page-height: 60px;
  $adfit-height: 90px;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .drawer {
    background: #d9d9d9;
    width: $drawer-width;
    height: 100%;
    color: rgba(0, 0, 0, 0.63);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .hamburger-icon {
      text-align: center;
      padding-top: 20px;
      cursor: pointer;
    }
    &.open {
      width: $drawer-open-width;
    }

    .top-buttons {
      padding: 10px;
      text-align: right;
      .pin-toggle {
        padding: 1px 4px;
        line-height: 1.1em;
        font-size: 12px;
        margin: 10px;
        border: 1px solid black;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    strong {
      white-space: nowrap;
    }

    -webkit-transition: width 0.25s;
    -moz-transition: width 0.25s;
    -ms-transition: width 0.25s;
    -o-transition: width 0.25s;
    transition: width 0.25s;
  }

  .appbar {
    position: absolute;
    top: 0;
    height: $appbar-height;
    border-bottom: 1px solid #d9d9d9;
    background-color: #ffffff;
    text-align: center;
    overflow: unset;
    z-index: 1;
    > div {
      max-width: 1000px;
      margin: auto;
      height: 100%;
      position: relative;
      text-align: right;
      .search-input-container {
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        .search-input {
          border-color: #d9d9d9;
          width: 200px;
          -webkit-transition: width 0.25s;
          -moz-transition: width 0.25s;
          -ms-transition: width 0.25s;
          -o-transition: width 0.25s;
          transition: width 0.25s;
          &:focus {
            width: 300px;
            @media (max-width: 330px) {
              width: 280px;
            }
          }
        }
        .autocomplete {
          position: absolute;
          background-color: #ffffff;
          right: 0;
          width: 300px;
          border: 1px solid #d9d9d9;

          @media (max-width: 330px) {
            width: 280px;
          }

          .project-name {
            max-width: 150px;
            margin-right: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            @media (max-width: 330px) {
              width: 130px;
            }
          }

          > div {
            font-size: 13px;
            > span {
              vertical-align: middle;
              display: inline-block;
            }
            padding: 10px 10px;
            cursor: pointer;
            &:hover,
            &.active {
              background-color: #efefef;
            }
          }
        }
      }
    }
    &.mobile {
      text-align: center;
    }
  }

  .appbar,
  > .content {
    -webkit-transition: left 0.25s;
    -moz-transition: left 0.25s;
    -ms-transition: left 0.25s;
    -o-transition: left 0.25s;
    transition: left 0.25s;

    left: $drawer-width;
    width: calc(100% - #{$drawer-width});
    &.mobile {
      left: 0;
      right: 0;
      width: 100%;
    }
    &.open {
      left: $drawer-open-width;
    }
    &.fixed {
      width: auto;
      right: 0;
    }
  }

  > .content {
    position: absolute;
    left: $drawer-width;
    top: $appbar-height;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    &.mobile {
      bottom: calc(#{$tab-page-height} + #{$adfit-height});
    }
  }

  .tab-pager {
    div {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: $tab-page-height;
    overflow: hidden;
    text-align: center;
    button {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .center-align-page {
    max-width: 1000px;
    padding: 20px;
    margin: auto;
    @media (max-width: 370px) {
      padding: 10px;
    }
  }

  .error-message {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}
