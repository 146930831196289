.build-rss-page {
  @media (min-width: 500px) {
    &.default-page {
      padding: 40px;
    }
  }

  $border-color: #d9d9d9;
  .step {
    margin-bottom: 2em;
    > h3 {
      margin: 0 0 20px;
    }

    button {
      background-color: #d9d9d9;
      height: 38px;
      border: none;
      position: relative;
      -webkit-transition: background-color, color 300ms;
      -moz-transition: background-color, color 300ms;
      -ms-transition: background-color, color 300ms;
      -o-transition: background-color, color 300ms;
      transition: background-color, color 300ms;
      &.active {
        background-color: #3D5AFE;
        color: #ffffff;
      }
    }

    .parse-result,
    .loaded-html,
    select,
    textarea,
    input {
      display: block;
      width: 100%;
      line-height: 1.2em;
      font-size: 14px;
      padding: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      outline: none;
      border: 1px solid $border-color;
      margin: 5px 0;
    }

    .parse-result,
    .loaded-html {
      padding: 0;
      margin-bottom: 20px;
    }

    .parse-result {
      padding: 10px;
      max-height: 150px;
      overflow-y: auto;

      > div {
        margin-bottom: 5px;
        line-height: 1.3em;
        > div {
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
          span {
            color: #388E3C;
            font-weight: bold;
          }
        }
      }
    }

    button {
      display: block;
      width: 100%;
    }

    .rss-field-selector {
      display: flex;
      align-items: center;
      .rss-field-name {
        display: block;
        flex: 0 0 80px;
      }
      select {
        margin-left: 10px;
        margin-right: 10px;
        display: block;
        &.error {
          border-color: red;
          -webkit-box-shadow: 0 0 5px 0 #ff000088;
          -moz-box-shadow: 0 0 5px 0 #ff000088;
          box-shadow: 0 0 5px 0 #ff000088;
        }
      }
    }
    .advanced-option {
      cursor: pointer;
    }

    &.step1 {
      @media (min-width: 700px) {
        $encoding-input-width: 165px;
        .input-div {
          display: block;
          input:nth-child(1) {
            // width: calc(100% - #{$encoding-input-width} - 10px);
            display: block;
            // margin-right: 10px;
          }
          input:nth-child(2) {
            display: inline-block;
            width: $encoding-input-width;
          }
        }
      }
      .input-label {
        font-size: 13px;
        font-weight: bold;
        padding-left: 5px;
      }
    }
    &.step2 {
      .rule-input {
        position: relative;
        p {
          margin: 0;
        }
        textarea {
          min-height: 160px;
          margin: 0;
        }
        .hint {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          > i {
            margin-right: 5px;
          }
          margin: 0 0 10px;
          padding: 3px;
          color: #333333;
          font-size: 13px;
          font-style: italic;
          strong {
            font-style: normal;
          }
        }
        .placeholder {
          cursor: text;
          color: #998;
          top: 6px;
          left: 7px;
          position: absolute;
          font-size: 14px;
          .title {
            margin-bottom: 5px;
          }
          .description {
            margin-bottom: 5px;
            p {
              margin-bottom: 3px;
            }
            margin-top: 5px;
          }
        }
      }
    }
    &.step4 {
      > div {
        .row {
          > span {
            width: 100px;
            display: inline-block;
          }
          > input {
            width: calc(100% - 100px);
            display: inline-block;
          }
        }
      }
    }
  }
}