$tab-page-height: 60px;
.adfit-container {
  position: fixed;
  bottom: 0;
  font-size: 0;
  left: 50%;
  transform: translateX(-50%);
  &.mobile {
    width: 100%;
    left: 0;
    transform: none;
    bottom: $tab-page-height;
  }
}
