.rss-builder-iframe {
  position: relative;
  .step-guide-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .step-guide {
      width: 15rem;
      background-color: rgb(214, 214, 214);
      padding: 1rem;
      border-radius: 1rem;
      color: rgba(77, 77, 77);
      margin: 0 1rem;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      min-height: 83px;
      h1 {
        font-size: 1.2rem;
        font-weight: strong;
        margin: 0 0 0.5rem;
      }
      p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.3;
        font-weight: bold;
        span.icon {
          vertical-align: middle;
        }
        &.count {
          margin: 0;
        }
      }
      &.active {
        background-color: #bbdefb;
      }
    }
  }
  .iframe-wrapper {
    position: relative;
    iframe {
      border: 1px solid #888888;
      border-radius: 0.5em;
    }
    .disable-iframe-div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);

      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  .step2 {
    > .row {
      display: flex;
      align-items: center;
      margin: 3px 0;
      > label {
        flex: 0 0 100px;
        font-size: 14px;
        font-weight: bold;
      }
      > div {
        flex: 1;
        input {
          margin: 0;
        }
      }
    }
    .result {
      > p {
        font-weight: bold;
      }
      .parse-result-table {
        img {
          width: 50px;
          height: 50px;
        }
        th:nth-of-type(1) {
          display: none;
        }
      }
    }
    .register-button {
      margin-top: 1em;
    }
  }
}
