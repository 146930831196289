@import url('//fonts.googleapis.com/earlyaccess/nanumgothic.css');
@import url('//fonts.googleapis.com/css?family=Roboto');
@import url('//fonts.googleapis.com/icon?family=Material+Icons');
@import url('//code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');

$highlight-color: #0366d6;
$gnb-height: 36px;
$primary-color: #24292e;

body, pre, textarea {
  margin: 0;
  font-family: 'Nanum Gothic';
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 8px 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 7px 0;
}

li {
  list-style-type: none;
}

.default-page {
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}

.default-page-no-padding {
  max-width: 1000px;
  margin: auto;
}

.Roboto {
  font-family: 'Roboto', sans-serif;
}

input[type="password"] {
  font-family: 'Roboto', sans-serif !important;
}

.inline-block {
  display: inline-block;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.blur1px {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.project-result {
  li {
    margin: 5px 0;
  }
}

.grey-group-box {
  background-color: #f6f8fa;
  border: 1px solid #E0E0E0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  color: #24292e;
}

.fade-in {
  animation: fadein 500ms 1;
}

.fade-in-fast {
  animation: fadein 250ms 1;
}

.highlight-color {
  color: $highlight-color;
}

.vertical-align-middle {
  vertical-align: middle;
}

.padding-10 {
  padding: 10px
}

.padding-15 {
  padding: 15px
}

.padding-left-right-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-5 {
  padding: 5px
}

.padding-20 {
  padding: 20px
}

.padding-left-10 {
  padding-left: 10px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.position-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.half-width {
  width: 50%
}

@keyframes fadein {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

.post-list-item {
  &:nth-child(1) {
    border-top: none;
  }
  border-top: 1px solid #E0E0E0;
}

.post-content {
  a {
    color: rgb(3, 102, 214);
    text-decoration: underline;
  }
}

.red-color {
  color: rgb(255, 64, 129);
}

.blue-color {
  color: rgb(3, 102, 214);
}

.underline {
  text-decoration: underline;
}

.overflow-auto {
  overflow: auto;
}

.link {
  color: $highlight-color;
  text-decoration: underline;
  cursor: pointer;
}

.project-news {
  ul {
    padding-left: 20px
  }
}

.button-icon {
  position: relative;
  top: 1px;
}

.timeline-item {
  position: relative;
  font-size: 13px;
  margin-bottom: 20px;
  li {
    margin: 5px 0;
  }
  p {
    margin: 5px 0;
  }
}

.full-width {
  width: 100%;
}

.how-to-use-content {
  padding: 0 10px;
  p {
    margin: 15px 0;
    font-size: 20px;
  }

  @media (min-width: 420px) and (max-width: 650px) {
    p {
      margin: 15px 0;
      font-size: 15px;
    }
  }

  @media (min-width: 380px) and (max-width: 420px) {
    p {
      margin: 15px 0;
      font-size: 15px;
      line-height: 15px;
    }
  }

  @media (max-width: 380px) {
    p {
      margin: 15px 0;
      font-size: 14px;
      line-height: 15px;
    }
  }
}

.hightlight-span {
  background-color: #ffff00;
  color: #000000;
}

* {
  -webkit-overflow-scrolling: touch;
}

.error-box {
  border-color: red !important;
  box-shadow: 0 0 5px 0 #ff000088;
}

.shake {
  animation: shake-animation 500ms
}

.vertical-align-all-child {
  > * {
    vertical-align: middle;
  }
}

@keyframes shake-animation {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.my-subscribes {
  ul {
    margin: 0;
    padding: 0;
  }

  .folder-name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    margin: 0;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    span {
      position: relative;
      top: -2px;
    }
  }
}