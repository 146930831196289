
.page-404 {
  $bgColor: #f7f7f7;
  $jaggedDistance: 32px;

  height: 100%;

  background-color: $bgColor;

  // boo
  $booSize: $jaggedDistance * 5;
  $booBg: $bgColor;
  $booShadow: darken($booBg, 5%);

  // face
  $booFaceSize: round($jaggedDistance / 1.3333);
  $booFaceColor: #000000;

  // ===========

  @keyframes floating {
    0% {
      transform: translate3d(0, 0, 0);
    }
    45% {
      transform: translate3d(0, -10%, 0);
    }
    55% {
      transform: translate3d(0, -10%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes floatingShadow {
    0% {
      transform: scale(1);
    }
    45% {
      transform: scale(.85);
    }
    55% {
      transform: scale(.85);
    }
    100% {
      transform: scale(1);
    }
  }

  // ===========

  @mixin border-bottom-jagged($size, $color-outer) {
    position: relative;
    padding-bottom: $size;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -($size / 1.7);
      bottom: -($jaggedDistance / 3.85);
      width: calc(100% + #{$size});
      height: $size;
      background-repeat: repeat-x;
      background-size: $size $size;
      background-position: left bottom;
      background-image: linear-gradient(-45deg, $color-outer ($size / 2), transparent 0),
      linear-gradient(45deg, $color-outer ($size / 2), transparent 0),
      linear-gradient(-45deg, $booFaceColor ($size / 1.7), transparent 0),
      linear-gradient(45deg, $booFaceColor ($size / 1.7), transparent 0);
    }
  }

  // ===========

  .container {
    font-family: 'Varela Round', sans-serif;
    color: $booFaceColor;
    position: relative;
    height: 100%;
    text-align: center;
    font-size: $jaggedDistance / 2;

    h1 {
      font-size: $jaggedDistance;
      margin-top: $jaggedDistance;
    }
    p {
      font-size: 20px;
    }
    a {
      padding: 10px 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: inline-block;
      border: 1px solid #000000;
      border-radius: 30px;
      background-color: transparent;
      font-size: 17px;
    }

    @media (max-width: 330px) {
      h1 {
        margin-top: 10px;
      }
      p {
        font-size: 18px;
      }
      a {
        margin-top: 5px;
      }
    }
  }

  .boo-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    paddig-top: $jaggedDistance * 2;
    paddig-bottom: $jaggedDistance * 2;
  }

  .boo {
    width: $booSize;
    height: $booSize + ($booSize * .15);
    background-color: $booBg;
    margin-left: auto;
    margin-right: auto;
    border: (($jaggedDistance / 1.65)  - ($jaggedDistance /2)) solid $booFaceColor;
    border-bottom: 0;
    overflow: hidden;
    border-radius: ($booSize / 2) ($booSize / 2) 0 0;
    box-shadow: -($jaggedDistance / 2) 0 0 2px rgba($booShadow, .5) inset;
    @include border-bottom-jagged($jaggedDistance, $bgColor);
    animation: floating 3s ease-in-out infinite;

    .face {
      width: $booFaceSize;
      height: $jaggedDistance / 10;
      border-radius: 5px;
      background-color: $booFaceColor;
      position: absolute;
      left: 50%;
      bottom: $jaggedDistance + $booFaceSize;
      transform: translateX(-50%);

      &::before,
      &::after {
        content: '';
        display: block;
        width: $booFaceSize / 4;
        height: $booFaceSize / 4;
        background-color: $booFaceColor;
        border-radius: 50%;
        position: absolute;
        bottom: $jaggedDistance + ($booFaceSize / 3);
      }

      &::before {
        left: -$booFaceSize;
      }

      &::after {
        right: -$booFaceSize;
      }
    }
  }

  .shadow {
    width: $booSize - $jaggedDistance;
    height: $jaggedDistance / 2;
    background-color: rgba($booShadow, .75);
    margin-top: $jaggedDistance * 1.25;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    animation: floatingShadow 3s ease-in-out infinite;
  }
}

.page-404.mobile {
  $bgColor: #f7f7f7;
  $jaggedDistance: 20px;

  height: 100%;

  background-color: $bgColor;

  // boo
  $booSize: $jaggedDistance * 5;
  $booBg: $bgColor;
  $booShadow: darken($booBg, 5%);

  // face
  $booFaceSize: round($jaggedDistance / 1.3333);
  $booFaceColor: #000000;

  // ===========

  @keyframes floating {
    0% {
      transform: translate3d(0, 0, 0);
    }
    45% {
      transform: translate3d(0, -10%, 0);
    }
    55% {
      transform: translate3d(0, -10%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes floatingShadow {
    0% {
      transform: scale(1);
    }
    45% {
      transform: scale(.85);
    }
    55% {
      transform: scale(.85);
    }
    100% {
      transform: scale(1);
    }
  }

  // ===========

  @mixin border-bottom-jagged($size, $color-outer) {
    position: relative;
    padding-bottom: $size;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -($size / 1.7);
      bottom: -($jaggedDistance / 3.85);
      width: calc(100% + #{$size});
      height: $size;
      background-repeat: repeat-x;
      background-size: $size $size;
      background-position: left bottom;
      background-image: linear-gradient(-45deg, $color-outer ($size / 2), transparent 0),
      linear-gradient(45deg, $color-outer ($size / 2), transparent 0),
      linear-gradient(-45deg, $booFaceColor ($size / 1.7), transparent 0),
      linear-gradient(45deg, $booFaceColor ($size / 1.7), transparent 0);
    }
  }

  // ===========

  .container {
    font-family: 'Varela Round', sans-serif;
    color: $booFaceColor;
    position: relative;
    height: 100%;
    text-align: center;
    font-size: $jaggedDistance / 2;

    h1 {
      font-size: $jaggedDistance;
      margin-top: $jaggedDistance;
    }
    p {
      font-size: 20px;
    }
    a {
      padding: 10px 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: inline-block;
      border: 1px solid #000000;
      border-radius: 30px;
      background-color: transparent;
      font-size: 17px;
    }

    @media (max-width: 330px) {
      h1 {
        margin-top: 10px;
      }
      p {
        font-size: 18px;
      }
      a {
        margin-top: 5px;
      }
    }
  }

  .boo-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    paddig-top: $jaggedDistance * 2;
    paddig-bottom: $jaggedDistance * 2;
  }

  .boo {
    width: $booSize;
    height: $booSize + ($booSize * .15);
    background-color: $booBg;
    margin-left: auto;
    margin-right: auto;
    border: (($jaggedDistance / 1.65)  - ($jaggedDistance /2)) solid $booFaceColor;
    border-bottom: 0;
    overflow: hidden;
    border-radius: ($booSize / 2) ($booSize / 2) 0 0;
    box-shadow: -($jaggedDistance / 2) 0 0 2px rgba($booShadow, .5) inset;
    @include border-bottom-jagged($jaggedDistance, $bgColor);
    animation: floating 3s ease-in-out infinite;

    .face {
      width: $booFaceSize;
      height: $jaggedDistance / 10;
      border-radius: 5px;
      background-color: $booFaceColor;
      position: absolute;
      left: 50%;
      bottom: $jaggedDistance + $booFaceSize;
      transform: translateX(-50%);

      &::before,
      &::after {
        content: '';
        display: block;
        width: $booFaceSize / 4;
        height: $booFaceSize / 4;
        background-color: $booFaceColor;
        border-radius: 50%;
        position: absolute;
        bottom: $jaggedDistance + ($booFaceSize / 3);
      }

      &::before {
        left: -$booFaceSize;
      }

      &::after {
        right: -$booFaceSize;
      }
    }
  }

  .shadow {
    width: $booSize - $jaggedDistance;
    height: $jaggedDistance / 2;
    background-color: rgba($booShadow, .75);
    margin-top: $jaggedDistance * 1.25;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    animation: floatingShadow 3s ease-in-out infinite;
  }
}