.project-list-item {
  position: relative;
  margin-bottom: 48px;
  > .left {
    width: 100px;
    display: inline-block;
    vertical-align: top;
  }
  > .left + .right {
    width: calc(100% - 100px);
  }
  .right {
    padding-left: 10px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: rgb(158, 158, 158);
    font-size: 12px;
  }

  .title {
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }

  .description {
    font-size: 14px;
    line-height: 1.25rem;
  }
}