.discover-page {
  height: 100%;
  > div {
    position: absolute;
    top: 50px;
    left: 50%;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    p.welcome {
      text-align: center;
      font-size: 30px;
      margin-bottom: 40px;
      line-height: 1.2em;
      @media(max-width: 410px) {
        font-size: 25px;
      }
    }

    .search-div {
      position: relative;
      margin: auto auto 30px;
      $default-color: #9e9e9e;
      $focused-color: #66BB6A;

      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      border: 1px solid $default-color;

      box-shadow: 0 0 10px #efefef;

      > i {
        font-size: 16px;
        position: absolute;
        left: 9px;
        top: 12px;
        color: $default-color;
      }
      > input {
        width: 100%;
        border: none;
        font-size: 18px;
        padding: 10px 10px 10px 35px;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }

      &.focus {
        border-color: $focused-color;
      }

      width: 300px;
      @media (min-width: 330px) and(max-width: 400px) {
        width: 280px;
      }

      @media (max-width: 330px) {
        width: 250px;
      }
    }

    > .top10-projects {
      > p {
        text-align: center;
        margin: 60px 0 40px;
      }
      .rank-item {
        position: relative;
        height: 100px;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        > .thumbnail {
          height: 100px;
          width: 100px;
          position: absolute;
          left: 0;
          top: 0;
          -webkit-background-size: contain;
          background: no-repeat center;
          background-size: contain;
        }
        > p {
          width: 100%;
          font-weight: bold;
          margin-left: 110px;
          text-align: center;
        }
        @media(max-width: 570px) {
          height: 80px;
          > p {
            margin-left: 95px;
          }
          > .thumbnail {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

}