$diamond-color: #24292e;
$loader-size: 5rem;
$animation-duration: 2.4s;

.diamonds {
  display: flex;
  flex-flow: row wrap;
  width: $loader-size;
  height: $loader-size;
  transform: rotateZ(45deg);

  .diamond {
    position: relative;
    height: 50%;
    flex-basis: 50%;
    transform: scale(1.1);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $diamond-color;
      animation: diamond $animation-duration infinite linear both;
      transform-origin: 100% 100%;
    }

    &-2 {
      transform: scale(1.1) rotateZ(90deg);

      &::before {
        animation-delay: 300ms;
      }
    }

    &-3 {
      transform: scale(1.1) rotateZ(180deg);

      &::before {
        animation-delay: 600ms;
      }
    }

    &-4 {
      transform: scale(1.1) rotateZ(270deg);

      &::before {
        animation-delay: 900ms;
      }
    }
  }
}

@keyframes diamond {
  0%, 10% {
    transform:perspective(14rem) rotateX(-180deg);
    opacity:0
  }
  25%, 75% {
    transform:perspective(14rem) rotateX(0deg);
    opacity:1
  }
  90%, 100% {
    transform:perspective(14rem) rotateY(180deg);
    opacity:0
  }
}